<template>
  <div class="container mx-auto border-t-2 pt-4">
    <span class="cursor-pointer" @click="router.go(-1)">
      <div
        class="bg-white border rounded-full fixed shadow-xl h-10 w-10 flex justify-center items-center left-4 top-20">
        <ChevronLeftIcon />
      </div>
    </span>
    <EventDetails :event-id="route.params.id" />
  </div>
</template>

<script lang="ts" setup>
import { definePage, useRouter } from 'vue-router/auto'
import { useRoute } from "vue-router/auto";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import EventDetails from "@/components/Events/EventDetails.vue";
const route = useRoute('/event-details/[id]');

const router = useRouter();


definePage({
  meta: {
    title: 'Event Details',
  },
})
/* document.title = "Events - " + event.value?.name; */
</script>
