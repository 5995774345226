import { ref, watchEffect } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { collection, getDocs, onSnapshot, orderBy, query, doc, getDoc } from 'firebase/firestore'
import { db } from '@/firebase'
import { defaultConverter, type IEvent } from '@/models'
import { useUserStore } from '@/stores/userStore'
import { useLocalStorage } from '@vueuse/core'

export const useEventsStore = defineStore('eventStore', () => {
  const eventsCollection = collection(db, 'events')
  const events = ref<IEvent[]>([])
  const eventsLoading = ref(false)
  const eventsToShow = ref(300)

  const cachedEvents = useLocalStorage('events', events, {
    serializer: {
      read: (v) => {
        const values = JSON.parse(v) as IEvent[]
        return values.map((v) => ({
          ...v,
          dates: v.dates.map((d) => new Date(d))
        }))
      },
      write: (v) => JSON.stringify(v)
    }
  })

  const cachedEventsVersion = useLocalStorage('events-version', '0')

  const eventsQuery = query(eventsCollection, orderBy('name')).withConverter(
    defaultConverter<IEvent>()
  )
  const { user } = storeToRefs(useUserStore())

  watchEffect(() => {
    if (user.value) {
      onSnapshot(eventsQuery, (querySnapshot) => {
        events.value = querySnapshot.docs.map((d) => d.data())
      })
    }
  })

  async function loadEvent(id: string) {
    //get document from firebase by id
    const docRef = doc(db, 'events', id).withConverter(defaultConverter<IEvent>())
    const event = (await getDoc(docRef)).data()
    if (event) {
      events.value.push(event)
    }
  }

  async function loadEvents() {
    eventsLoading.value = true
    const metadata = await getDoc(doc(db, 'metadata', 'metadata-versions'))
    const version = metadata.data()?.events
    if (version > cachedEventsVersion.value) {
      cachedEvents.value = []
      cachedEventsVersion.value = version
    }

    if (cachedEvents.value.length > 0) {
      console.log('loading events from cache')
      events.value = cachedEvents.value
    } else {
      console.log('loading events from api')
      events.value = (await getDocs(eventsQuery)).docs.map((d) => d.data())
    }

    eventsLoading.value = false
  }

  return { events, loadEvents, loadEvent, eventsLoading, eventsToShow }
})
