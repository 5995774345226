import { ref, watchEffect } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  limit,
  getDoc,
  doc
} from 'firebase/firestore'
import { db } from '@/firebase'
import { defaultConverter, type IActivity } from '@/models'
import { useUserStore } from './userStore'
import { useLocalStorage } from '@vueuse/core'

export const useActivitiesStore = defineStore('activityStore', () => {
  const activitiesCollection = collection(db, 'activities')
  const activities = ref<IActivity[]>([])
  const activitiesLoading = ref(false)

  const activitiesToShow = ref(10)

  const cachedActivities = useLocalStorage('activities', activities)
  const cachedActivitiesVersion = useLocalStorage('activities-version', '0')

  const activitiesQuery = query(activitiesCollection, orderBy('name')).withConverter(
    defaultConverter<IActivity>()
  )
  const { user } = storeToRefs(useUserStore())

  watchEffect(() => {
    if (user.value) {
      onSnapshot(activitiesQuery, (querySnapshot) => {
        activities.value = querySnapshot.docs.map((d) => d.data())
      })
    }
  })

  async function loadActivities() {
    activitiesLoading.value = true

    const metadata = await getDoc(doc(db, 'metadata', 'metadata-versions'))
    const version = metadata.data()?.activities
    if (version > cachedActivitiesVersion.value) {
      cachedActivities.value = []
      cachedActivitiesVersion.value = version
    }

    if (cachedActivities.value.length > 0) {
      console.log('loading activities from cache')
      activities.value = cachedActivities.value
    } else {
      console.log('loading activities from api')
      activities.value = (await getDocs(activitiesQuery)).docs.map((d) => d.data())
    }

    activitiesLoading.value = false
  }

  async function loadActivity(id: string) {
    //get document from firebase by id
    const docRef = doc(db, 'activities', id).withConverter(defaultConverter<IActivity>())
    const activity = (await getDoc(docRef)).data()
    if (activity) {
      activities.value.push(activity)
    }
  }

  return { activities, loadActivities, loadActivity, activitiesLoading, activitiesToShow }
})
