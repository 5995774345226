<template>
  <div>
    <Sidebar v-model:visible="isOpen" :pt="{
      headerContent: 'w-full'
    }">
      <template #header>
        <h2 class="text-xl font-bold text-center w-full">Filters</h2>
      </template>
      <div class="mx-2 flex flex-col gap-3">

        <MultiSelect v-model="searchParams.dayOfWeek" option-label="label" option-value="value" placeholder="All Days"
          :options="dayOfWeekOptions" :pt="{
            label: '!whitespace-normal !overflow-auto !p-1',
            panel: '!z-[2000]'
          }" :pt-options="{ mergeProps: true, mergeSections: true }" :show-toggle-all="false">
          <template #value="{ value }">

            <div v-if="value.length === 0 || value.length == 7" class="">All Days</div>
            <Tag v-else v-for="v in value" :key="v" class="mr-1">
              {{ dayOfWeek(v) }}
            </Tag>
          </template>
        </MultiSelect>
        <FilterTags v-model="searchParams.age" :options="ageList.filter(a => a != 'Secondary')" label="All Ages">
        </FilterTags>
        <FilterTags v-model="searchParams.type" :options="playgroupTagsList" label="Looking for something specific?">
        </FilterTags>
        <FilterTags v-model="searchParams.excludedTypes" :options="playgroupTagsList" label="Don't show me...">
        </FilterTags>

        <SelectButton class="flex w-full" v-model="searchParams.shelter" :options="unTypedShelterSearch"
          aria-labelledby="basic" />

        <SelectButton class="flex w-full" v-model="searchParams.time" :options="unTypedPlaygroupTimeSearch"
          aria-labelledby="basic" />

        <InputText placeholder="Search by Location name" v-model="searchParams.name" />
        <FilterTags v-model="searchParams.postcodes" :options="allPostcodes" label="All Postcodes">
        </FilterTags>

        <div class="flex items-center justify-center gap-2">
          <label>No Booking Required</label>
          <InputSwitch v-model="searchParams.noBookingNeeded" size="small" />
        </div>

        <div class="flex items-center justify-center gap-2">
          <label>Not Term time only</label>
          <InputSwitch v-model="searchParams.notTermTimeOnly" size="small" />
        </div>


        <div class="flex gap-2">
          <Button v-if="hasFilters" severity="secondary" outlined @click="clearFilters">
            <Icon icon="heroicons-backspace" class="mr-2" />
            Clear
          </Button>
          <Button class="flex-grow justify-center" severity="secondary" @click="search">
            Search
          </Button>
        </div>

        <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
          role="alert">
          Please note that playgroups info can change often so please always check before you attend! Do contact me with
          any
          changes you're aware of. Thank you!
        </div>


      </div>
    </Sidebar>
    <div class="shadow-md border-gray-200  flex custom-scrollbar sticky top-0 z-10 bg-white">
      <div class="flex flex-col justify-center ml-2 ">
        <button
          class="border-gray-800 bg-gray-800 text-white border-2 p-2 rounded-full flex items-center gap-1 focus:ring-2 focus:ring-white ring-inset focus:bg-gray-900 hover:bg-gray-900"
          @click="isOpen = true">
          <Icon icon="heroicons:cog-6-tooth" />
          Filters
        </button>
      </div>
      <ul class="flex text-sm font-medium text-center mx-2 whitespace-nowrap overflow-x-scroll ">

        <QuickFilter icon="mdi:heart" label="Favourites" :active="searchParams.onlyFavorites"
          @click="quickFilter(s => s.onlyFavorites = !s.onlyFavorites)" />

        <QuickFilter v-for="day in dayOfWeekOptions.filter(s => s.value != 0)" :key="day.value"
          icon="tabler:calendar-filled" :label="day.label.slice(0, 3)"
          :active="searchParams.dayOfWeek.includes(day.value)"
          @click="quickFilter(s => s.dayOfWeek = toggleElementInArray(s.dayOfWeek, day.value))" />

      </ul>
    </div>
    <Sidebar v-model:visible="showSidebar" position="right" :pt="{
      root: 'w-full'
    }" :pt-options="{ mergeProps: true }" :modal="true">
      <div ref="sidebar">
        <PlaygroupDetails :playgroup-id="selectedPlaygroupId" />
      </div>
    </Sidebar>


    <div class="mt-4">

      <div class="mx-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3" v-auto-animate>
        <Card class="relative" :key="playgroup.id" v-for="playgroup in visiblePlaygroups"
          @click="selectPlaygroup(playgroup)">
          <template #title>
            <span class="mr-4">
              {{ playgroup.name }}
            </span>
          </template>
          <template #subtitle>
            <div class="text-sm max-h-20 overflow-y-auto custom-scrollbar">
              <span>
                {{ playgroup.description }}
              </span>
            </div>
          </template>
          <template #content>
            <button class="absolute top-[3%] right-[3%] p-2" outlined severity="secondary" v-auto-animate
              @click.prevent.stop="userStore.toggleFavourite(playgroup.id)">
              <Icon v-if="favourites.includes(playgroup.id)" icon="mdi:heart" class="text-red-500" />
              <Icon v-else icon="mdi:heart-outline" />
            </button>
            <div class="grid gap-4">
              <div class="flex justify-between">
                <div class="flex flex-col gap-2">
                  <div class="flex flex-col">
                    <span class="text-sm font-semibold text-gray-500">Day{{ playgroup.day.length > 1 ? 's' : ''
                      }}</span>
                    <span class="font-bold">{{ [...playgroup.day].sort().map((d) => dayOfWeek(d)).join(', ') }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span class="text-sm font-semibold text-gray-500">Time</span>
                    <span class="font-bold">{{ playgroup.startTime }} - {{ playgroup.endTime }}</span>
                  </div>
                </div>
                <AgeTagDisplay severity="secondary" class="flex-col" :age="playgroup.age" />
              </div>
              <div class="flex justify-between">
                <div class="flex flex-col">
                  <span class="text-sm font-semibold text-gray-500">Location</span>
                  <span class="font-bold">{{ playgroup.locationName }}</span>
                  <span class="text-sm">{{ playgroup.address }}</span>
                </div>
                <div class="flex flex-col items-center justify-center">
                  <div class="flex items-center text-sm text-gray-700 underline" v-if="location.accuracy > 0">
                    <MapPinIcon />
                    <ItemMap :item="playgroup">
                      <template #activator>
                        <DistanceDisplayer :user-location="location" :item="playgroup" />
                      </template>
                    </ItemMap>
                  </div>
                </div>
              </div>



              <div class="flex gap-2">
                <TagList v-if="playgroup.tags && playgroup.tags.length > 0" class="flex-wrap" :tags="playgroup.tags" />
                <Tag>
                  {{ playgroup.shelter === "Hybrid" ? "Indoors and outdoors" : playgroup.shelter }}
                </Tag>
                <Tag v-if="playgroup.termTimeOnly">Term time only</Tag>
              </div>

              <div class="flex flex-col">
                <span class="text-sm font-semibold text-gray-500">Booking Required?</span>
                <span class="font-bold">
                  <span>{{ playgroup.bookInAdvance ? 'Yes' : 'No' }}</span>
                </span>
              </div>
              <div class="flex flex-col">
                <span class="text-sm font-semibold text-gray-500">Contact</span>
                <a class="font-semibold ">
                  {{ playgroup.contactAddress }}
                </a>
              </div>

              <a class="mb-6 block underline font-bold" :href="playgroup.url" @click.stop target="_blank">Website <i
                  class="i-heroicons-link"></i></a>
            </div>
          </template>
        </Card>


      </div>
    </div>

    <Message v-if="visiblePlaygroups.length === 0" class="m-4" severity="info" :closable="false">
      No playgroups found for this search, please change your filters and try again.
    </Message>

    <div ref="contentEnd"></div>

    <span @click="mapOpen = true"
      class="bg-primary text-white rounded-full max-w-fit py-2 px-4 flex items-center gap-2 fixed bottom-[14%] left-1/2 -translate-x-1/2 z-10 ring ring-primary ring-offset-2 cursor-pointer">
      <MapIcon class="h-5 w-5" />
      Show map
    </span>
    <Sidebar v-model:visible="mapOpen" position="bottom" class="h-[95%]" :pt="{
      header: '!p-2'
    }" :pt-options="{ mergeProps: true }">
      <PlaygroupMap class="h-full" :playgroups="searchedPlaygroups" @selectItem="selectPlaygroup" />
    </Sidebar>
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'
import { usePlaygroupsStore } from '@/stores/playgroupStore';
import { storeToRefs } from 'pinia';
import AgeTagDisplay from '@/components/AgeTagDisplay.vue';
import { useUserStore } from '@/stores/userStore';
import { MapPinIcon } from '@heroicons/vue/24/outline'
import DistanceDisplayer from '@/components/DistanceDisplayer.vue';
import { Icon } from '@iconify/vue';
import { computed, onMounted, ref, watch } from 'vue';
import type { Age, DayOfWeek, IPlaygroup, PlaygroupTags, PlaygroupTimeSearch, ShelterSearch } from '@/models';
import { ageList, playgroupTagsList, unTypedShelterSearch, dayOfWeek, dayOfWeekOptions, unTypedPlaygroupTimeSearch } from '@/models';
import { stringFilterMatch, FilterTags, getDistance } from 'ui';
import PlaygroupMap from '@/components/PlaygroupMap.vue';
import { MapIcon } from '@heroicons/vue/24/solid'
import { useIntersectionObserver } from '@vueuse/core';
import { useRouter } from 'vue-router';
import QuickFilter from '@/components/QuickFilter.vue';

definePage({
  meta: {
    title: 'Playgroups',
  },
})

const playgroupsStore = usePlaygroupsStore();
const { playgroups } = storeToRefs(playgroupsStore);

onMounted(async () => {
  await playgroupsStore.loadPlaygroups();
  setTimeout(() => {
    resume()
  }, 1000)
});



const userStore = useUserStore()
const { location, favourites } = storeToRefs(userStore);

const isOpen = ref(false);

interface SearchParams {
  dayOfWeek: DayOfWeek[];
  shelter: ShelterSearch;
  age: Age[];
  type: PlaygroupTags[]
  excludedTypes: PlaygroupTags[];
  name: string;
  postcodes: string[];
  onlyFavorites: boolean;

  noBookingNeeded: boolean
  notTermTimeOnly: boolean,
  time: PlaygroupTimeSearch
}

function defaultSearchParams(): SearchParams {
  return {
    dayOfWeek: [],
    shelter: "Don't mind",
    age: [],
    type: [],
    excludedTypes: [],
    name: "",
    onlyFavorites: false,
    noBookingNeeded: false,
    notTermTimeOnly: false,
    postcodes: [],
    time: "Don't mind"
  };
}

const hasFilters = computed(() => {
  return (
    searchParams.value.name !== "" ||
    searchParams.value.shelter !== "Don't mind" ||
    searchParams.value.time !== "Don't mind" ||
    searchParams.value.age.length > 0 ||
    searchParams.value.type.length > 0 ||
    searchParams.value.excludedTypes.length > 0 ||
    searchParams.value.dayOfWeek.length > 0 ||
    searchParams.value.postcodes.length > 0
  );
});

function clearFilters() {
  searchParams.value = { ...defaultSearchParams() }
}

const searchParams = ref<SearchParams>({
  ...defaultSearchParams()
});

const currentSearchParams = ref<SearchParams>({
  ...defaultSearchParams()
});

const sortFunction = computed(() => {
  if (location.value.accuracy > 0) {
    return (a: IPlaygroup, b: IPlaygroup) =>
      getDistance(location.value, a, "miles") > getDistance(location.value, b, "miles")
        ? 1
        : -1;
  } else {
    return (a: IPlaygroup, b: IPlaygroup) => {
      //sort randomly
      return Math.min(...a.day) > Math.min(...b.day) ? 1 : -1;
    };
  }
});

const searchedPlaygroups = computed(() => {

  return [
    ...playgroups.value
      .filter((playgroup) => stringFilterMatch(playgroup.locationName, currentSearchParams.value.name))

      .filter((playgroup) => currentSearchParams.value.shelter === "Don't mind" || playgroup.shelter === "Hybrid" || currentSearchParams.value.shelter === playgroup.shelter)
      .filter((playgroup) => {
        return currentSearchParams.value.time === "Don't mind" || currentSearchParams.value.time == 'AM' && Number(playgroup.startTime.split(":")[0]) < 12 || currentSearchParams.value.time == 'PM' && Number(playgroup.startTime.split(":")[0]) >= 12
      })
      .filter((playgroup) => {
        return currentSearchParams.value.type.length === 0 || playgroup.tags?.filter((a) => currentSearchParams.value.type.includes(a)).length > 0;
      })
      .filter((playgroup) => currentSearchParams.value.excludedTypes.length === 0 || currentSearchParams.value.excludedTypes.every(t => !playgroup.tags?.includes(t)))
      .filter((playgroup) => currentSearchParams.value.age.length === 0 || playgroup.age.filter((a) => currentSearchParams.value.age.includes(a)).length > 0)
      .filter((playgroup) => currentSearchParams.value.postcodes.length === 0 || currentSearchParams.value.postcodes.includes(getPostcodeFromAddress(playgroup)!))
      .filter((playgroup) => currentSearchParams.value.dayOfWeek.length === 0 || playgroup.day.filter((a) => currentSearchParams.value.dayOfWeek.includes(a)).length > 0)
      .filter((playgroup) => currentSearchParams.value.noBookingNeeded == false || playgroup.bookInAdvance == false)
      .filter((playgroup) => currentSearchParams.value.notTermTimeOnly == false || playgroup.termTimeOnly == false)
      .filter((playgroup) => !searchParams.value.onlyFavorites || favourites.value.includes(playgroup.id))
  ]
    .sort(sortFunction.value)
});


function search() {
  isOpen.value = false;
  currentSearchParams.value = { ...searchParams.value };


  const searchJson = JSON.stringify(searchParams.value, (key, value) => {
    const typedKey: keyof SearchParams = key as keyof SearchParams;
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return undefined;
    }

    if (typedKey == "shelter" && value == "Don't mind") {
      return undefined;
    }
    if (typedKey == "time" && value == "Don't mind") {
      return undefined;
    }

    return value;
  });

  window.umami.track('playgroup-search', currentSearchParams.value)
}


function getPostcodeFromAddress(playgroup: IPlaygroup) {
  return playgroup.address.match(/([A-Za-z]{1,2}\d{1,2})(\s?(\d?\w{2}))?/g)?.[0]?.split(' ')[0];

}

const allPostcodes = computed(() => {
  const fullPostcodes = playgroups.value.map((p) => getPostcodeFromAddress(p)).filter((p) => p) as string[];
  //Sort postcodes like this S1 S2 S3 S10
  return [...new Set(fullPostcodes)].sort((a, b) => {
    const [strA, numA] = a.match(/\d+|[a-zA-Z]+/g) ?? '';
    const [strB, numB] = b.match(/\d+|[a-zA-Z]+/g) ?? '';
    return Number(numA) - Number(numB) || strA.localeCompare(strB);
  });

})


function quickFilter(filter: (searchParams: SearchParams) => void) {
  filter(searchParams.value);
  search();
}

function toggleElementInArray<T>(arr: T[], val: T) {
  return arr.includes(val) ? arr.filter(el => el !== val) : [...arr, val];
}


const mapOpen = ref(false);
const selectedPlaygroupId = ref('');
const showSidebar = ref(false);

async function selectPlaygroup(playgroup: IPlaygroup) {
  await router.replace({ query: { playgroup: playgroup.id } })
  selectedPlaygroupId.value = playgroup.id;
  showSidebar.value = true;
}

const contentEnd = ref(null)

const { stop, resume } = useIntersectionObserver(
  contentEnd,
  () => {
    playgroupsStore.playgroupsToShow += 10;
  },
  {
    immediate: false
  }
)



const visiblePlaygroups = computed(() => searchedPlaygroups.value.slice(0, playgroupsStore.playgroupsToShow))

const router = useRouter();
router.beforeEach((to, from, next) => {
  if (showSidebar.value) {
    showSidebar.value = false
    next(false)
  } else {
    next()
  }
})


</script>


<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f97316;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f97316;
}
</style>
