import * as Sentry from "@sentry/vue";
import type { Plugin } from 'vue';
import type { Router } from 'vue-router';

const SentryPlugin : Plugin = {
  install(app , options :  { router : Router }) {
    Sentry.init({
      app,
      dsn: "https://ec5361bb3f920a96ca838a67e77a44fb@o4506072822579200.ingest.sentry.io/4506072824152064",
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
          routingInstrumentation: Sentry.vueRouterInstrumentation(options.router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

  }
} 

export default SentryPlugin;
