<template>
  <div class="container mx-auto border-t-2 pt-4 bg">
    <div class="cursor-pointer" @click="router.go(-1)">
      <div
        class="bg-white border rounded-full fixed shadow-xl h-10 w-10 flex justify-center items-center left-4 top-20">
        <ChevronLeftIcon />
      </div>
    </div>
    <ActivityDetails :activity-id="id" />
  </div>
</template>

<script setup lang="ts">
import { useRouteParams } from '@vueuse/router';
import {
  ChevronLeftIcon
} from '@heroicons/vue/24/outline';
import { definePage, useRouter } from 'vue-router/auto'
import ActivityDetails from '@/components/Activity/ActivityDetails.vue';

const id = useRouteParams('id', null, { transform: String })
const router = useRouter();

definePage({
  meta: {
    title: 'Activity Details',
  },
})
//document.title = "Activity - " + activity.value?.name;


</script>
