<template>
  <ContactForm :db="db" />
</template>


<script setup lang="ts">
import { db } from '@/firebase';
import { definePage } from 'vue-router/auto'
definePage({
  meta: {
    title: 'Contact',
  },
})

</script>
