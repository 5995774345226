<template>
  <div class="mx-2">
    <div class="flex my-2">
      <SelectButton class="flex w-full" :pt-options="{ mergeProps: true }" v-model="selectedRoute"
        :options="selectOptions" aria-labelledby="basic" />
    </div>
    <Suspense>
      <RouterView v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </RouterView>
      <template #fallback>
        Loading...
      </template>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router/auto';
import SelectButton from 'primevue/selectbutton';
import { computed, onMounted, ref, watch } from 'vue';
import { definePage } from 'vue-router/auto'
import { useUserStore } from '@/stores/userStore';
const router = useRouter();

const SubRoutes = {
  Activities: '/admin/activities',
  Events: '/admin/events',
  Playgroups: '/admin/playgroups'
} as const

type Route = keyof typeof SubRoutes;

const selectOptions = computed(() => Object.keys(SubRoutes));



const selectedRoute = ref<Route>('Activities');

definePage({
  meta: {
    title: 'Admin',
  },
})

const userStore = useUserStore();


onMounted(async () => {

  switch (router.currentRoute.value.name) {
    case '/admin/activities':
      selectedRoute.value = 'Activities'
      break;
    case '/admin/events':
      selectedRoute.value = 'Events'
      break;
    case '/admin/playgroups':
      selectedRoute.value = 'Playgroups'
      break;
  }
})

watch(selectedRoute, (val: Route) => {
  switch (val) {
    case 'Activities':
      router.push({ name: '/admin/activities' });
      break;
    case 'Events':
      router.push({ name: '/admin/events' });
      break;
    case 'Playgroups':
      router.push({ name: '/admin/playgroups' });
      break;
  }
});

if (router.currentRoute.value.name === '/admin') {
  router.replace({ name: '/admin/activities' });
}


</script>
