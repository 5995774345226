<template>
  <div>
    <Sidebar v-model:visible="isOpen" :pt="{
      headerContent: 'w-full'
    }">
      <template #header>
        <h2 class="text-xl font-bold text-center w-full">Filters</h2>
      </template>
      <div class="mx-2 flex flex-col gap-3">

        <FilterTags v-if="timeFrame !== 'Permanent' && activeEventTypeList.length > 1" v-model="searchParams.eventType"
          :options="activeEventTypeList" label="All Events"></FilterTags>



        <FilterTags v-model="searchParams.age" :options="ageList" label="All Ages"></FilterTags>
        <FilterTags v-model="searchParams.price" :options="priceList" label="All Prices"></FilterTags>
        <FilterTags v-model="searchParams.type" :options="typeList" label="Looking for something specific?">
        </FilterTags>
        <FilterTags v-model="searchParams.excludedTypes" :options="dontShowTypes" label="Don't show me..."></FilterTags>

        <SelectButton class="flex w-full" v-model="searchParams.shelter" :options="unTypedShelterSearch"
          aria-labelledby="basic" />
        <PInputText placeholder="Search by Name" v-model="searchParams.name" />

        <div class="flex items-center justify-center gap-2">
          <label>No Booking Required</label>
          <InputSwitch v-model="searchParams.noBookingNeeded" size="small" />
        </div>

        <Calendar v-if="timeFrame !== 'Permanent'" v-model="searchParams.date" placeholder="Any Date" class="w-full">
        </Calendar>

        <div class="flex gap-2">
          <Button v-if="hasFilters" severity="secondary" outlined @click="clearFilters">
            <Icon icon="heroicons-backspace" class="mr-2" />
            Clear
          </Button>
          <Button class="flex-grow justify-center" severity="secondary" @click="search">
            Search
          </Button>
        </div>
      </div>
    </Sidebar>
    <div class="shadow-md border-gray-200  flex custom-scrollbar sticky top-0 z-10 bg-white">
      <div class="flex flex-col justify-center ml-2 ">
        <button
          class="border-gray-800 bg-gray-800 text-white border-2 p-2 rounded-full flex items-center gap-1 focus:ring-2 focus:ring-white ring-inset focus:bg-gray-900 hover:bg-gray-900"
          @click="isOpen = true">
          <Cog6ToothIcon />
          Filters
        </button>
      </div>
      <ul class="flex text-sm font-medium text-center mx-2 whitespace-nowrap overflow-x-scroll ">
        <QuickFilter icon="mdi:heart" label="Favourites" :active="searchParams.onlyFavorites"
          @click="quickFilter(s => s.onlyFavorites = !s.onlyFavorites)" />

        <QuickFilter icon="healthicons:animal-cow-outline" label="Animals"
          :active="searchParams.type.includes('Animals')"
          @click="quickFilter(s => s.type = toggleElementInArray(s.type, 'Animals'))" />

        <QuickFilter icon="mdi:car-emergency" label="Vehicles" :active="searchParams.type.includes('Vehicles')"
          @click="quickFilter(s => s.type = toggleElementInArray(s.type, 'Vehicles'))" />

        <QuickFilter icon="simple-line-icons:energy" label="Energy-buster"
          :active="searchParams.type.includes('Energy-buster')"
          @click="quickFilter(s => s.type = toggleElementInArray(s.type, 'Energy-buster'))" />

        <QuickFilter icon="gis:hiker" label="Big Day Out" :active="searchParams.type.includes('Big Day Out')"
          @click="quickFilter(s => s.type = toggleElementInArray(s.type, 'Big Day Out'))" />
      </ul>
    </div>

    <div class="mt-2">

      <div class="grid w-full grid-cols-3  bg-white p-2 mb-2 rounded-xl">
        <div v-for="timeRange in timeFilterList" :key="timeRange">
          <input type="radio" v-model="timeFrame" :id="timeRange" :value="timeRange" class="peer hidden" />
          <label :for="timeRange"
            class="block cursor-pointer select-none rounded-xl py-1 text-center peer-checked:bg-primary  peer-checked:text-white text-sm">
            {{ timeRange }}
          </label>
        </div>
      </div>
    </div>

    <span class="mx-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3" v-if="activitiesLoading">
      <EmptyCard />
      <EmptyCard />
      <EmptyCard />

    </span>


    <ActivityList v-if="timeFrame === 'Permanent'" :activities="searchedActivities"></ActivityList>
    <EventList v-if="timeFrame !== 'Permanent'" :events="searchedEvents"></EventList>


  </div>
</template>

<script async setup lang="ts">
import {
  type Age,
  type IActivity,
  type Playground,
  type Price,
  type Type,
  ageList,
  priceList,
  typeList,
  type IEventWithActivity,
  type EventType,
  activeEventTypeList,
  unTypedShelterSearch,
  type ShelterSearch,
  getEventDates
} from '@/models';
import { useActivitiesStore } from '@/stores/activityStore';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/userStore';
import { getDistance, stringFilterMatch } from 'ui';
import { computed, onMounted, ref } from 'vue';


import { Cog6ToothIcon } from '@heroicons/vue/20/solid'
import { FilterTags } from 'ui';
import PInputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';
import { useUrlSearchParams } from '@vueuse/core';
import { definePage, useRoute } from 'vue-router/auto';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';
import { useEventsStore } from '@/stores/eventStore';
import ActivityList from '@/components/ActivityList.vue'
import EventList from '@/components/EventsList.vue'
import dayjs from 'dayjs';
import QuickFilter from '@/components/QuickFilter.vue';

definePage({
  meta: {
    title: 'Activities List',
  },
})


const timeFilterList = ['Permanent', 'This Month', 'Next Month'] as const;
export type TimeFilter = (typeof timeFilterList)[number]

interface SearchParams {
  shelter: ShelterSearch;
  price: Price[];
  age: Age[];
  type: (Type | "Has a playground")[];
  excludedTypes: Type[];
  name: string;
  date: string | null;
  onlyFavorites: boolean;

  eventType: EventType[];
  noBookingNeeded: boolean
}

const subTypes: Type[] = ["Big Day Out", "Climbing", "Cinema", "Escape Rooms", "Library", "Pottery", "Pub/Restaurant", "Soft play", "Swimming"];
const dontShowTypes = computed(() => subTypes);

const { favourites } = storeToRefs(useUserStore());


const sortFunction = computed(() => {
  if (location.value.accuracy > 0) {
    return (a: IActivity, b: IActivity) =>
      getDistance(location.value, a, "miles") > getDistance(location.value, b, "miles")
        ? 1
        : -1;
  } else {
    return () => {
      //sort randomly
      return Math.random() > 0.5 ? 1 : -1;
    };
  }
});

function defaultSearchParams(): SearchParams {
  return {
    shelter: "Don't mind",
    price: [],
    age: [],
    type: [],
    excludedTypes: [],
    name: "",
    date: null,
    onlyFavorites: false,
    eventType: [],
    noBookingNeeded: false,
  };
}

const hasFilters = computed(() => {
  return (
    searchParams.value.name !== "" ||
    searchParams.value.shelter !== "Don't mind" ||
    searchParams.value.price.length > 0 ||
    searchParams.value.age.length > 0 ||
    searchParams.value.type.length > 0
  );
});

function clearFilters() {
  searchParams.value = { ...defaultSearchParams() }
}

const searchParams = ref<SearchParams>({
  ...defaultSearchParams()
});

const searchedActivities = computed(() => {
  const playgroundSearch: Playground[] = [];
  if (searchParams.value.type.includes("Has a playground")) {
    playgroundSearch.push("Yes");
    playgroundSearch.push("Within 5min walk");
  }
  return [
    ...activities.value
      .filter((activity) => !activity.bareBones)
      .filter((activity) => stringFilterMatch(activity.name, searchParams.value.name))
      .filter((activity) => searchParams.value.shelter === "Don't mind" || activity.shelter === "Hybrid" || searchParams.value.shelter === activity.shelter)
      .filter((activity) => {
        return playgroundSearch.length === 0 || playgroundSearch.includes(activity.playground);
      })
      .filter((activity) => searchParams.value.price.length === 0 || searchParams.value.price.includes(activity.price))
      .filter((activity) => {
        return searchParams.value.type.filter(t => t !== "Has a playground").length === 0 || activity.type?.filter((a) => searchParams.value.type.filter(t => t !== "Has a playground").includes(a)).length > 0;
      })
      .filter((activity) => searchParams.value.excludedTypes.length === 0 || searchParams.value.excludedTypes.every(t => !activity.type?.includes(t)))
      .filter((activity) => searchParams.value.age.length === 0 || activity.age.filter((a) => searchParams.value.age.includes(a)).length > 0)
      .filter((activity) => !searchParams.value.onlyFavorites || favourites.value.includes(activity.id))
  ]
    .sort(sortFunction.value)
});



const eventSortFunction = computed(() => {
  return (a: IEventWithActivity, b: IEventWithActivity) => {
    //use dayjs to compare dates

    const aEarliestDate = new Date(Math.min(...a.dates.map(d => d.getTime())));

    const bEarliestDate = new Date(Math.min(...b.dates.map(d => d.getTime())));

    return aEarliestDate >= bEarliestDate ? 1 : -1;
  }
});

const eventsWithActivities = computed<IEventWithActivity[]>(() => {
  if (activitiesLoading.value) {
    return [];
  }
  function getActivity({ name, locationId }: { name: string, locationId: string }) {
    const activity = activities.value.find(a => a.id === locationId)
    if (activity?.latitude == null || activity?.longitude == null) {
      console.error(`Event ${name} has no location`)
      console.error(`Activity ${activity?.name} has no location`)
      return null
    }
    return activity
  }
  return events.value
    .filter(e => e.live)
    .map(e => ({
      ...e,
      activity: getActivity(e)!,
      dates: getEventDates(e).filter(d => timeFrame.value != 'Next Month' ? true : dayjs(d).isAfter(dayjs().endOf('month')))

    })).filter(s => s.activity?.latitude && s.activity?.longitude);
});


const searchedEvents = computed(() => {
  return [
    ...eventsWithActivities.value
      .filter((event) => stringFilterMatch(event.name, searchParams.value.name) || stringFilterMatch(event.activity.name, searchParams.value.name))
      .filter((event) => searchParams.value.price.length === 0 || searchParams.value.price.includes(event.price))
      .filter((event) => searchParams.value.shelter === "Don't mind" || event.shelter === "Hybrid" || searchParams.value.shelter === event.shelter)
      .filter((event) => searchParams.value.eventType.length === 0 || searchParams.value.eventType.includes(event.type))
      .filter((event) => searchParams.value.date === null || event.dates.some(d => (dayjs(d).isSame(dayjs(searchParams.value.date)))))
      .filter((event) => searchParams.value.age.length === 0 || event.age.filter((a) => searchParams.value.age.includes(a)).length > 0)
      .filter((event) => {
        return searchParams.value.type.filter(t => t !== "Has a playground").length === 0 || event.tags?.filter((a) => searchParams.value.type.filter(t => t !== "Has a playground").includes(a)).length > 0;
      })
      .filter((event) => searchParams.value.excludedTypes.length === 0 || searchParams.value.excludedTypes.every(t => !event.type?.includes(t)))
      .filter((event) => searchParams.value.noBookingNeeded == true && event.bookingType == null || searchParams.value.noBookingNeeded == false)
      .filter(e => {
        const eventDates = getEventDates(e);
        if (eventDates && eventDates.length > 0) {
          return eventDates.some(d => dayjs(d).endOf('day').isAfter(dayjs()))
        }
      })
      .filter((event) => !searchParams.value.onlyFavorites || userStore.favourites.includes(event.id))
      .filter((event) => {

        const eventDates = getEventDates(event);
        if (timeFrame.value === "This Month") {
          return eventDates.some(d => dayjs(d).isSame(dayjs(), 'month') && dayjs(d).endOf('day').isAfter(dayjs()))
        }
        if (timeFrame.value === "Next Month") {
          return eventDates.some(d => dayjs(d).isSame(dayjs().add(1, 'month'), 'month'))
        }
      })
  ].sort(eventSortFunction.value);
});

const { name, query } = useRoute();
const router = useRouter();

const isOpen = ref(false);
const { activities, activitiesLoading } = storeToRefs(useActivitiesStore());
const { events, eventsLoading } = storeToRefs(useEventsStore());

onMounted(async () => {
  if (name === '/' && query.timeRange) {
    timeFrame.value = query.timeRange as TimeFilter
    router.replace({
      query: {
      }
    })
  }

});


const { location } = storeToRefs(useUserStore());
const userStore = useUserStore();

function getUrlParamAsArray(param: string | string[] | any[] | undefined) {
  if (param === undefined) return [];

  return Array.isArray(param) ? param : [param];
}

const params = useUrlSearchParams<SearchParams>('history')
searchParams.value.age = getUrlParamAsArray(params.age)
searchParams.value.price = getUrlParamAsArray(params.price)
searchParams.value.type = getUrlParamAsArray(params.type)
searchParams.value.excludedTypes = getUrlParamAsArray(params.excludedTypes)
searchParams.value.shelter = params.shelter ?? "Don't mind"
searchParams.value.name = params.name ?? ""
searchParams.value.onlyFavorites = String(params.onlyFavorites) == 'true' ? true : false

async function search() {
  userStore.getLocation();
  isOpen.value = false;
  params.age = searchParams.value.age;
  params.price = searchParams.value.price;
  params.type = searchParams.value.type;
  params.excludedTypes = searchParams.value.excludedTypes;
  params.shelter = searchParams.value.shelter;
  params.name = searchParams.value.name;
  params.onlyFavorites = searchParams.value.onlyFavorites;

  window.umami.track('activity-search', searchParams.value)

}

function quickFilter(filter: (searchParams: SearchParams) => void) {
  filter(searchParams.value);
  search();
}

function toggleElementInArray<T>(arr: T[], val: T) {
  return arr.includes(val) ? arr.filter(el => el !== val) : [...arr, val];
}


const timeFrame = ref<TimeFilter>("Permanent");


</script>


<style lang="scss">
.custom-scrollbar {
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    cursor: pointer;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

  }

  ::-webkit-scrollbar-track {
    background-color: rgba(229, 231, 235, var(--bg-opacity));
    cursor: pointer;
    /*background: red;*/
  }

  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #a0aec0;
    /*outline: 1px solid slategrey;*/
  }
}

.event-banner {
  background: rgb(153, 210, 246);
  background: radial-gradient(circle, rgba(153, 210, 246, 1) 0%, rgba(243, 144, 247, 1) 100%);
}

.snow {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.7%3Bfill:%23FFFFFF%3B%7D.st3%7Bopacity:0.4%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st3' cx='4' cy='14' r='1'/%3E%3Ccircle class='st3' cx='43' cy='3' r='1'/%3E%3Ccircle class='st3' cx='31' cy='30' r='2'/%3E%3Ccircle class='st3' cx='19' cy='23' r='1'/%3E%3Ccircle class='st3' cx='37' cy='22' r='1'/%3E%3Ccircle class='st3' cx='43' cy='16' r='1'/%3E%3Ccircle class='st3' cx='8' cy='45' r='1'/%3E%3Ccircle class='st3' cx='29' cy='39' r='1'/%3E%3Ccircle class='st3' cx='13' cy='37' r='1'/%3E%3Ccircle class='st3' cx='47' cy='32' r='1'/%3E%3Ccircle class='st3' cx='15' cy='4' r='2'/%3E%3Ccircle class='st3' cx='9' cy='27' r='1'/%3E%3Ccircle class='st3' cx='30' cy='9' r='1'/%3E%3Ccircle class='st3' cx='25' cy='15' r='1'/%3E%3Ccircle class='st3' cx='21' cy='45' r='2'/%3E%3Ccircle class='st3' cx='42' cy='45' r='1'/%3E%3C/svg%3E");
  background-position: 0px 0px;
  animation: animatedBackground 15s linear infinite;

}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 0px 300px;
  }
}
</style>
