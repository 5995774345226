<template>
  <nav class="bg-white">
    <div class="mx-auto w-full px-2 sm:px-6 lg:px-8 shadow-md">
      <div class="relative flex h-16 items-center justify-between">
        <div class="sm:hidden">
          <!-- Mobile menu button-->
          <div class="dropdown">
            <div tabindex="0" role="button" class="btn btn-outline min-h-fit h-auto py-2.5 btn-primary m-1 ">About
            </div>
            <ul tabindex="0"
              class="dropdown-content z-[100] menu p-2 shadow bg-white border border-primary rounded-box w-max">
              <li>
                <RouterLink active-class="bg-primary text-white" class="btn btn-ghost hover:bg-primary hover:text-white"
                  :to="{ name: '/' }">
                  <a>Home</a>
                </RouterLink>
              </li>
              <li>
                <RouterLink active-class="bg-primary text-white" class="btn btn-ghost hover:bg-primary hover:text-white"
                  :to="{ name: '/about' }">
                  <a>About</a>
                </RouterLink>
              </li>
              <li>
                <RouterLink active-class="bg-primary text-white" class="btn btn-ghost hover:bg-primary hover:text-white"
                  :to="{ name: '/contact' }">
                  <a>Contact</a>
                </RouterLink>
              </li>
              <Permission admin>
                <li>
                  <RouterLink active-class="bg-primary text-white"
                    class="btn btn-ghost hover:bg-primary hover:text-white" :to="{ name: '/admin' }"> Admin
                  </RouterLink>
                </li>
              </Permission>
              <li>
                <a data-umami-event="follow on instagram" target="_blank"
                  href="https://instagram.com/funday_sheffield?igshid=ZDdkNTZiNTM="
                  class="btn btn-ghost hover:bg-primary hover:text-white">
                  <img class="h-8 w-8"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png" />
                </a>
              </li>
            </ul>
          </div>

        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <RouterLink :to="{ name: '/' }">
              <img class="block h-12 w-auto lg:hidden" src="/logo.svg" alt="Your Company">
              <img class="hidden h-12 w-auto lg:block" src="/logo.svg" alt="Your Company">
            </RouterLink>
          </div>
          <div class="hidden ml-6 sm:block">
            <div class="flex space-x-4 h-full items-center">
              <NavLinks size="sm" />
            </div>
          </div>
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.fundaysheffield.twa" target="_blank">
          <button class="btn btn-outline min-h-fit h-auto py-2.5 btn-primary m-1" outlined size="small">Use App</button>
        </a>

        <!--  <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <Login />
        </div> -->
      </div>
    </div>


  </nav>


  <ConfirmDialog />
  <Toast />

  <main class="mb-10">
    <Suspense>
      <RouterView v-slot="{ Component }">
        <transition name="route" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </RouterView>
      <template #fallback>
        Loading...
      </template>
    </Suspense>


  </main>


  <TheFooter class="mb-16" />


  <div class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-300 ">
    <div class="grid h-full max-w-lg grid-cols-5 mx-auto font-medium text-gray-600 relative">
      <RouterLink active-class="text-primary font-bold" class="inline-flex flex-col items-center justify-center px-3"
        :to="{ name: '/' }">
        <Icon class="text-2xl group-hover:text-primary" icon="ph:balloon-duotone" />
        <span class="text-sm  group-hover:text-primary ">Activities</span>
      </RouterLink>
      <RouterLink active-class="text-primary font-bold" class="inline-flex flex-col items-center justify-center px-3"
        :to="{ name: '/playgroups' }">
        <Icon class="text-2xl group-hover:text-primary" icon="ph:users-three-duotone" />
        <span class="text-sm  group-hover:text-primary ">Playgroups</span>
      </RouterLink>


      <div></div>

      <a class="inline-flex flex-col items-center justify-center px-3" href="https://buy.stripe.com/fZe02Zaw5fCY6QM6op">
        <Icon class=" text-2xl group-hover:text-primary" icon="simple-icons:buymeacoffee" />
        <span class="text-sm  group-hover:text-primary ">Support</span>
      </a>

      <Login />



    </div>

    <div class="absolute -top-5 bg-white p-2 rounded-full left-1/2 -translate-x-1/2">
      <RouterLink active-class="" class="" :to="{ name: '/' }">

        <div class="bg-primary rounded-full p-6 h-8 w-8  flex items-center justify-center ">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 83" fill="none" class="size-8">
              <path
                d="M64 34C64 56.5 34 79 34 79C34 79 4 56.5 4 34C4 26.0435 7.16071 18.4129 12.7868 12.7868C18.4129 7.16071 26.0435 4 34 4C41.9565 4 49.5871 7.16071 55.2132 12.7868C60.8393 18.4129 64 26.0435 64 34Z"
                stroke="white" stroke-width="8" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M21.719 26.523C24.839 18.075 34.141 17.755 40.83 22.033C48.506 26.919 47.136 36.52 42.656 43.027C38.282 49.427 25.669 53.354 24.468 42.12C24.012 38.024 26.592 33.5 29.778 31.165C30.6872 30.4704 31.8143 30.1231 32.9567 30.1854C34.0991 30.2478 35.1818 30.7157 36.01 31.505C36.5018 31.9998 36.8801 32.5955 37.119 33.251C37.3588 33.9072 37.4513 34.6081 37.39 35.304L37.05 37.084C36.095 40.079 34.845 40.354 33.303 37.907C34.619 36.339 32.378 34.067 31.051 35.645C26.677 40.851 32.708 45.428 37.666 41.672C42.359 38.12 41.191 29.682 35.734 27.58C23.11 22.704 15.264 46.9 28.365 51.924C41.467 56.949 52.71 37.864 48.612 26.748C43.76 13.594 23.641 12.155 18.64 25.681C17.918 27.623 20.997 28.444 21.709 26.535L21.719 26.523Z"
                fill="white" />
            </svg>
          </div>
        </div>

      </RouterLink>
    </div>
  </div>
</template>


<script setup lang="ts">
import { onAuthStateChanged, type User } from 'firebase/auth';
import { auth } from './firebase';
import { useUserStore } from './stores/userStore';
import Login from '@/components/Login.vue';
const mobileMenuOpen = ref(false);
const store = useUserStore();
import NavLinks from './components/NavLinks.vue';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import { useConfirm } from "primevue/useconfirm";
import TheFooter from './components/TheFooter.vue';
import { Icon } from '@iconify/vue';
//@ts-ignore
import { registerSW } from 'virtual:pwa-register'

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    store.user = JSON.parse(JSON.stringify(user)) as User;
    //setAnalyticsCollectionEnabled(analytics, false);
    // ...

    userStore.loadUserFavourites();
  } else {
    store.user = null;
    // User is signed out
    // ...
  }
});

const { loadActivities } = useActivitiesStore();
const { loadEvents } = useEventsStore();
const userStore = useUserStore();
onMounted(async () => {
  loadActivities();
  loadEvents();
})

const route = useRoute();

const router = useRouter();
router.beforeEach((to, from, next) => {
  nextTick(() => {
    document.body.tabIndex = 0
    document.body.focus()
    document.body.tabIndex = -1
  })

  document.title = `Funday Sheffield - ${to.meta.title ?? ''}`;
  next();
});

watch(route, () => {
  setTimeout(() => {
    mobileMenuOpen.value = false;
  }, 100);
}, { deep: true, immediate: true })

import { nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/auto';
import { useActivitiesStore } from './stores/activityStore';
import { useEventsStore } from './stores/eventStore';


const confirm = useConfirm();

const updateSW = registerSW({
  immediate: true,
  onNeedRefresh() {
    confirm.require({
      header: "Funday",
      message: `There is a new version of Funday available!`,
      accept: () => updateSW(),
      acceptLabel: "Update",
      rejectClass: "!hidden",

    })

  },
  onOfflineReady() { },
})





</script>

<style>
/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
