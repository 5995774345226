import './styles.css'
import 'leaflet/dist/leaflet.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import PrimeVue from 'primevue/config'
import PTag from 'primevue/tag'
import PButton from 'primevue/button'
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'

import { SquadStatsTheme } from './theme'
import { usePassThrough } from 'primevue/passthrough'
import Tailwind from 'primevue/passthrough/tailwind'

import SentryPlugin from './sentry'

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          return resolve(savedPosition)
        }
        resolve({ left: 0, top: 0 })
      }, 500)
    })
  },
  history: createWebHistory(),
  extendRoutes: (routes) => {
    const aboutRoute = routes.find((r) => r.name === '/about')
    if (aboutRoute) {
      aboutRoute.meta ??= {}
      aboutRoute.meta.title = 'About'
    }

    const homeRoute = routes.find((r) => r.name === '/')
    if (homeRoute) {
      homeRoute.meta ??= {}
      homeRoute.meta.title = 'Home'
    }

    routes.push({
      name: 'old-list',
      path: '/list',
      redirect: { name: '/' }
    })

    // completely optional since we are modifying the routes in place
    return routes
  }
})

const app = createApp(App)

const Theme = usePassThrough(Tailwind, SquadStatsTheme, {
  mergeSections: false,
  mergeProps: false
})
app.use(PrimeVue, { unstyled: true, pt: Theme })
app.component('PTag', PTag)
app.component('PButton', PButton)
app.use(ConfirmationService)
app.use(ToastService)

app.use(createPinia())
app.use(router)

if (import.meta.env.VITE_APP_USE_SENTRY == 'true') {
  app.use(SentryPlugin, { router })
}

if (window.location.origin.includes('localhost')) {
  localStorage.setItem('umami.disabled', '1')
}

app.use(autoAnimatePlugin)

app.mount('#app')
