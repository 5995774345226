import _definePage_default_0 from '/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/activities/[id].vue?definePage&vue'
import _definePage_default_3 from '/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/admin.vue?definePage&vue'
import _definePage_default_4 from '/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/contact.vue?definePage&vue'
import _definePage_default_5 from '/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/event-details/[id].vue?definePage&vue'
import _definePage_default_6 from '/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/playgroups.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/about.md'),
    /* no children */
  },
  {
    path: '/activities',
    /* internal name: '/activities' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: '/activities/[id]',
        component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/activities/[id].vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  _mergeRouteRecord(
  {
    path: '/admin',
    name: '/admin',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/admin.vue'),
    children: [
      {
        path: 'activities',
        name: '/admin/activities',
        component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/admin/activities.vue'),
        /* no children */
      },
      {
        path: 'events',
        name: '/admin/events',
        component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/admin/events.vue'),
        /* no children */
      },
      {
        path: 'playgroups',
        name: '/admin/playgroups',
        component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/admin/playgroups.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_3
  ),
  {
    path: '/confirm-support',
    name: '/confirm-support',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/confirm-support.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/contact',
    name: '/contact',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/contact.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  {
    path: '/event-details',
    /* internal name: '/event-details' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':id',
        name: '/event-details/[id]',
        component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/event-details/[id].vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/events',
    /* internal name: '/events' */
    /* no component */
    children: [
      {
        path: '',
        name: '/events/',
        component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/events/index.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/playgroups',
    name: '/playgroups',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/playgroups.vue'),
    /* no children */
  },
  _definePage_default_6
  ),
  {
    path: '/privacy',
    name: '/privacy',
    component: () => import('/home/runner/work/SmithTech/SmithTech/apps/funday/src/pages/privacy.md'),
    /* no children */
  }
]
