import { onMounted, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { type User } from 'firebase/auth'
import { useGeolocation, useLocalStorage } from '@vueuse/core'
import { collection, doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '@/firebase'
import { defaultConverter, type IUserData } from '@/models'

export const useUserStore = defineStore('userStore', () => {
  const user = ref<User | null>(null)

  const favourites = useLocalStorage<string[]>('favourites', [])

  async function loadUserFavourites() {
    if (user.value !== null) {
      const docRef = doc(db, 'users', user.value.uid).withConverter(defaultConverter<IUserData>())
      const docMaybe = await getDoc(docRef)
      if (docMaybe.exists()) {
        const userData = docMaybe.data()
        favourites.value = userData?.favourites ?? []
      } else {
        setDoc(docRef, { favourites: favourites.value })
      }
    }
  }

  async function toggleFavourite(id: string) {
    if (favourites.value.includes(id)) {
      favourites.value = favourites.value.filter((favourite: string) => favourite !== id)
    } else {
      favourites.value.push(id)
    }

    if (user.value !== null) {
      const docRef = doc(db, 'users', user.value.uid).withConverter(defaultConverter<IUserData>())
      await setDoc(docRef, { favourites: favourites.value })
    }
  }

  const { coords, resume } = useGeolocation({
    enableHighAccuracy: true,
    immediate: false
  })

  onMounted(async () => {
    const result = await navigator?.permissions?.query({ name: 'geolocation' })
    if (result?.state === 'granted') {
      getLocation()
    }
  })
  function getLocation() {
    resume()
  }

  return { user, location: coords, getLocation, favourites, loadUserFavourites, toggleFavourite }
})
