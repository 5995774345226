<script lang="ts">
import { defineComponent, h } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/userStore";

export default defineComponent({
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const { user } = storeToRefs(useUserStore());
    return () => {
      if (user.value === null) {
        return null;
      }

      const isAdmin = user.value.email?.includes('poppyjs5@gmail.com') || user.value.email?.includes('tom.smith2711@gmail.com');

      if (props.admin && !isAdmin) {
        return null;
      }

      if (slots && slots.default) {
        return h("span", slots.default());
      }

    };
  }
});
</script>
