<template>
  <RouterLink :activeClass="activeClass" :class="computedClasses" :to="{ name: '/' }"> Activities</RouterLink>
  <RouterLink :activeClass="activeClass" :to="{ name: '/playgroups' }" :class="computedClasses"> Playgroups</RouterLink>
  <RouterLink :activeClass="activeClass" :to="{ name: '/contact' }" :class="computedClasses">Contact</RouterLink>
  <RouterLink :activeClass="activeClass" :class="computedClasses" :to="{ path: '/about' }"> About</RouterLink>
  <Permission admin>
    <RouterLink :activeClass="activeClass" :class="computedClasses" :to="{ name: '/admin' }"> Admin</RouterLink>
  </Permission>
  <a data-umami-event="Support us button" @click="supportUsClicked" target="_blank"
    href="https://buy.stripe.com/fZe02Zaw5fCY6QM6op"
    class="flex text-white bg-gradient-to-r from-primary via-primary to-primary hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-primary-300 dark:focus:ring-primary-800 shadow-sm shadow-primary/50 dark:shadow-lg dark:shadow-primary-800/80 rounded-lg text-sm px-5 py-2 text-center">Support
    us
    <Icon icon="simple-icons:buymeacoffee" />
  </a>


</template>

<script lang="ts" setup>
import { computed, type PropType } from "vue";
import { Icon } from "@iconify/vue";
import Permission from "@/components/Permission.vue";

const props = defineProps({
  size: {
    type: String as PropType<'sm' | 'base'>,
    default: 'base'
  }
})

const baseLinkClasses = 'text-gray-800 hover:bg-primary hover:text-white block rounded-md py-2 font-medium'

const linkSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-sm px-2'
    case 'base':
    default:
      return 'text-base px-3'
  }
})

const computedClasses = computed(() => {
  return `${baseLinkClasses} ${linkSize.value}`
})
const activeClass = 'bg-primary text-white'



function supportUsClicked() {
  /*  trackEvent('support-us-nav') */
}
</script>
